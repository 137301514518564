import Vue from 'vue'
import { BootstrapVue, } from 'bootstrap-vue'

// Composition API
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/mixins'
import { EventBus } from "@/libs/event-bus"

import '../modules'

var moment = require('moment-timezone');
moment.locale("da")
moment.tz.setDefault("Europe/Berlin");


import numeral from "numeral"
import "numeral/locales/da-dk"
numeral.locale("da-dk")

// BSV Plugin Registration
Vue.use(BootstrapVue, {
    BFormDatepicker: {
        locale: "da",
        placeholder: "Vælg dato",
        startWeekday: "1",
        hideHeader: true
    }
})

import * as GoogleMaps from './components/gmap'
Vue.use(GoogleMaps, { load: { key: "AIzaSyCD8jM-85SCvOMvAfFSGiKU3EkbWBDKx0U", libraries: [] } })

import VueOffline from 'vue-offline'
Vue.use(VueOffline)

import VueTimers from 'vue-timers'
Vue.use(VueTimers)

import VueVirtualScroller from 'vue-virtual-scroller'
Vue.use(VueVirtualScroller)

import { Plugin as Vue2StoragePlugin } from 'vue2-storage';
Vue.use(Vue2StoragePlugin, {
//    prefix: 'app_',
//    driver: 'local',
//    ttl: 60 * 60 * 24 * 1000, // 24 hours
//    replacer: (key, value) => value
});


// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$moment = moment
Vue.prototype.$numeral = numeral
Vue.prototype.$eventBus = EventBus

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
